@import '~antd/dist/antd.less';

// @border-radius-base: 5px;

//button
@btn-font-weight: 500;
@btn-default-color: @primary-color;
//@btn-default-bg: @component-background;
@btn-default-border: @primary-color;
@border-radius-base: 5px;

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 10px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: @primary-color;
}

.ant-drawer-header {
  height: 64px;
  display: flex;
  align-items: center;
  background: #263238;
  border-bottom: 1px solid #263238;
  border-radius: unset;
}

.ant-drawer-title {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.ant-drawer-close {
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  color: #e8505b;
  padding: 24px;
  display: none;
}

.ant-drawer-close:hover {
  color: #e8505b;
  filter: brightness(110%);
}

.ant-drawer-body {
  background: #ffffff;
}

.ant-skeleton-element {
  width: 100%;
}

th {
  text-align: inherit;
}

@primary-color: #e73a55;