.singleModelRelationContainer {
  width: 100%;
  min-height: 74px;
  //border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 15px 10px 15px 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  .contentContainer {
    display: flex;
    align-items: center;
    width: 100%;

    .iconWrapper {
      font-size: 18px;
      padding: 10px;
      height: 100%;
      width: 36px;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      //line-height: 20px;
      display: flex;
      align-items: center;
    }

    .desc {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      //line-height: 18px;
      display: flex;
      align-items: center;
      color: #687074;
    }

    .tag {
      color: #ea3a60;
    }
  }

  .dropDownMenuIcon {
    font-style: 24px;
    margin-right: 4px;
  }
}

.connectionSettingDrawerContainer {
  .updateRelationBtn,
  .updateFieldBtn {
    position: absolute !important;
    bottom: 20px !important;
    width: 86% !important;
  }

  .topContent {
    margin-bottom: 40px;
  }
}

.drawerDynamicFormList {
  .dynamicListItem:not(:last-child) {
    margin-bottom: 4px;
  }

  .addBtn {
    margin-top: 10px !important;
  }
}
