.layoutWrapper {
  height: 100%;

  .layoutContent {
    width: calc(100vw - 215px);
    padding-left: 16px;
    padding-top: 16px;

    .layoutContentInner {
      background-color: #fff;
      min-height: 100%;
      border: 1px solid #e5e6ea;
      border-radius: 4px;
      overflow: auto;
      height: calc(100vh - 80px);
    }
  }

  .sidebar,
  .mediaSidebar,
  .logicSidebar {
    background-color: #fff !important;
    border-right: 1px solid #d0d0d08c !important;
    padding: 1rem;
    overflow: auto;
    height: calc(100vh - 65px);
    // padding-top: 64px !important;
  }

  .mediaSidebar {
    padding-right: 0;
  }

  .site-layout-bg {
    background: #fff;
  }
}

.contentHeader {
  padding: 16px 20px;

  :global .ant-page-header-heading-left {
    margin: 0;

    .ant-page-header-heading-title {
      font-size: 22px;
      font-weight: 700;
    }
  }
}

.authLayout {
  height: 100vh;
  width: 100%;
  overflow: auto;

  .leftColContentWrapper {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
    }
  }
}
