.projectUsagesContainer {
  .progressContainer {
    width: 100%;
    font-family: 'Raleway' sans-serif;

    .singleProgressWrapper {
      margin: 15px auto;

      & > span {
        font-size: 14px;
        line-height: 16px;
      }

      .progressWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > span {
          padding: auto 10px;
          margin: 0 10px;
          font-size: 14px;
          line-height: 16px;
          color: #9197a0;
        }

        .progress {
          max-width: 800px;
        }
      }
    }
  }
}
