.createField {
  .checkbox:not(:last-child) {
    margin-bottom: 2px !important;
  }

  .updateFieldBtn {
    position: absolute !important;
    bottom: 20px !important;
    width: 86% !important;
  }

  .topContent {
    margin-bottom: 40px;
  }
}
