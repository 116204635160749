.root {
  .searchAndFilter {
    display: flex;

    :global {
      .ant-input-search {
        max-width: 400px;
      }
    }
  }

  :global {
    .infinite-scroll-component {
      overflow-x: hidden !important;

      .ant-spin-spinning {
        display: block;
      }
    }
  }

  .imgCard {
    :global {
      border: 2px #f9f6f6 solid;

      .ant-card-cover {
        padding-top: 1px;
        height: 150px;
        overflow: hidden;
        border-radius: 10px;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1);
          transition: 0.3s;
        }

        :local .imgOverlay {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #00000070;
          color: #fff;
          font-size: 40px;
          cursor: pointer;
          opacity: 0;
          transition: 0.3s;
        }

        &:hover {
          :local .imgOverlay {
            opacity: 1;
          }

          img {
            transform: scale(1.1);
          }
        }
      }

      .ant-card-body {
        padding: 0px 0px 10px 15px;
        display: flex;
        align-items: center;

        :local {
          .details {
            margin-top: 0.5rem;
            flex-grow: 1;

            .title {
              font-size: 14px;
              margin-bottom: 0;
              font-weight: 500;
            }

            .time {
              font-size: 10px;
              margin-bottom: 0;
            }

            :global {
              div {
                font-size: 10px;
              }
            }
          }

          .dropDropdown {
            transform: rotate(90deg);
            font-size: 22px;
            width: 40px;
          }
        }
      }
    }
  }
}

.searchInput {
  :global .ant-input {
    height: 36px;
  }
}
