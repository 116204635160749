.root {
  display: flex;

  .modelListWrapper {
    flex-grow: 1;
    padding-right: 400px;
  }

  .modelSidebar {
    width: 400px;
    flex-shrink: 0;
    position: fixed;
    right: 0;
    overflow: auto;

    .modelSidebarInner {
      padding-bottom: 50px;
    }
  }

  :global .ant-page-header-heading-extra {
    .ant-space-align-center {
      gap: 1.5rem !important;
    }
  }
}

.checkbox:not(:last-child) {
  margin-bottom: 2px !important;
}

.singleFieldContainer {
  width: 100%;
  height: 62px;
  border: 1px dashed #000000;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin: 10px;
  cursor: pointer;

  .contentContainer {
    display: flex;
    align-items: center;

    .iconWrapper {
      padding: 10px;
      height: 100%;
      width: 34px;
      font-size: 18px;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #687074;
    }

    .desc {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #687074;
    }
  }
}

.dropDownMenuIcon {
  font-style: 24px;
}

.modelContainer {
  // .structureContainer {
  //   margin: 15px 10px;
  // }
  height: calc(100vh - 220px);

  .title {
    margin: 0 0 15px 1px;
    padding: 5px 0px 5px;
    font-style: normal;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 26px !important;
    display: flex;
    align-items: center;
    color: #687074;
  }

  .sectionTitle {
    font-size: 16px;
    font-weight: 600;
    opacity: 0.85;
  }

  .addBtn {
    height: 34px;
    margin: 0 1px 10px;
  }
}
