@import 'App.scss';

.root {
  width: 100%;
}

.infoListWrapper {
  padding: 25px 0;
  margin-left: -40px;

  ul {
    list-style-type: none;

    li > span {
      line-height: 28px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0px;
      text-align: left;
      color: #343d48;
    }

    .checkIcon {
      margin-right: 8px;
      color: $primary-color;
    }
  }
}