// .formWrapper * {
//   font-family: Roboto !important;
// }

#new-project .ant-form-vertical .ant-form-item-label > label {
  display: none !important;
  visibility: none;
}

// .logo {
//   position: absolute;
//   width: 133px;
//   height: 40px;
//   left: 20px;
//   background-image: url(/assets/svg/login/site_logo_login.svg);
//   margin-top: 20px;
//   float: left;
//   background-repeat: no-repeat;
// }

// .logoWrapper {
//   position: absolute;
//   z-index: 1;
// }

.mainRow {
  height: 100vh;
}

.leftColumn {
  overflow: hidden;
}

.rightColumn {
  overflow: scroll;
  scrollbar-width: none;
}

@media (max-width: 768px) {
  .leftColumn {
    display: none;
  }
  .rightColumn {
    margin-top: 60px;
  }
}


.leftColContentWrapper {
  display: flex;
}

.rightColContentWrapper {
  @extend .leftColContentWrapper;
}

.formWrapper {
  // width: 620px;
  height: 100%;
}

.formContentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around !important;
  min-width: 440px;
}

.title {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #000000;

  &_2 {
    @extend .title;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
  }
}

.desc {
  @extend .title;
  font-size: 13px;
  line-height: 22px;
  opacity: 0.85;
}

.projectCreatedTxt {
  font-size: 21px;
  line-height: 26px;
  color: #000000;
}

.btn {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin: 15px 15px 15px 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 8px 40px !important;
  width: 144px;
}

.btnCancel {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  padding-right: 30px !important;
  padding-left: 30px !important;
  width: 144px !important;
  background: #ffffff;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);

  &:hover:focus:active {
    color: #ffffff !important;
  }
}

.btnDisable:hover,
.btnDisable:active {
  color: #e94a62 !important;
}

@media (min-width: 979px) {
  .rightColContentWrapper {
    justify-content: flex-start;
    padding-left: 30px;
    width: 100%;
  }
}
