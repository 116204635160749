@import 'App.scss';

.apiExplorer {
  height: auto;
  overflow: auto;
  .tabs {
    :global {
      .ant-tabs-tab-active {
        background-color: $primary-color !important;
        span {
          color: #fff;
        }
      }
    }
    .tab {
      overflow: auto;
    }
  }
}
