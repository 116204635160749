@import 'App.scss';

.createBtn {
  width: 100%;
  position: sticky !important;
  box-shadow: none;
  font-size: 12px !important;
  border-radius: 5px !important;
  margin-bottom: 20px;
  height: 28px !important;
  line-height: 1;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.menuWrapper {
  .menuGroup {
    margin-bottom: 1.5rem;

    .groupTitle {
      font-size: 10px;
      font-weight: 700;
      color: #9197a0;
      margin-bottom: 0.5rem;
    }

    .menu {
      border: none;

      .menuItem {
        padding: 0 !important;
        border-radius: 5px;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        height: 28px;
        line-height: 1;

        &::after {
          display: none;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }

        :global {
          .ant-menu-title-content {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .menuDetails {
          font-size: 12px;
          margin-left: 0.5rem;
          line-height: 1.2;
          text-transform: capitalize;
          /* padding: 8px;
              margin: 10px; */
        }
      }

      :global {
        .ant-menu-item-selected {
          background-color: $primary-color;
          color: #fff;
        }

        .ant-menu-submenu {
          .ant-menu-submenu-title {
            height: 28px;
            border-radius: 5px;
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;

            .ant-menu-title-content {
              height: unset;
              line-height: 1.2;

              font-size: 12px;
              display: inline-flex;
              align-items: center;
            }
          }

          .ant-menu-sub {
            padding-left: 1rem;
            font-size: 12px;
            padding-top: 10px;
          }

          &.ant-menu-submenu-selected {
            .ant-menu-submenu-title {
              background-color: $primary-color;

              .ant-menu-title-content {
                color: #fff;
              }

              .ant-menu-item-icon,
              .ant-menu-submenu-arrow {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .checkboxWrapper {
      margin-bottom: 1.5rem;

      :global {
        .ant-checkbox-group-item {
          width: 100%;
        }

        .ant-checkbox-wrapper {
          margin-left: 0;
          width: 100%;
          margin-bottom: 0.5rem;
          font-size: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.sortableMenuWrapper {
  .sortableMenuGroup {
    margin-bottom: 1.5rem;

    .sortableGroupTitle {
      font-size: 10px;
      font-weight: 700;
      color: #9197a0;
      margin-bottom: 0.5rem;
    }

    .sortableMenu {
      border: none;

      .sortList {
        .sortMenuItem {
          padding: 0 !important;
          border-radius: 5px;
          padding-left: 0.5rem !important;
          padding-right: 0.5rem !important;
          height: 28px;
          line-height: 1;

          &::after {
            display: none;
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }

          :global {
            .ant-menu-title-content {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }

          .menuDetails {
            font-size: 12px;
            margin-left: 0.5rem;
            line-height: 1.2;
            text-transform: capitalize;
            /* padding: 8px;
                margin: 10px; */
          }
        }

        :global {
          .ant-menu-item-selected {
            background-color: $primary-color;
            color: #fff;
          }

          .ant-menu-submenu {
            .ant-menu-submenu-title {
              height: 28px;
              border-radius: 5px;
              padding-left: 0.5rem !important;
              padding-right: 0.5rem !important;

              .ant-menu-title-content {
                height: unset;
                line-height: 1.2;

                font-size: 12px;
                display: inline-flex;
                align-items: center;
              }
            }

            .ant-menu-sub {
              padding-left: 1rem;
              font-size: 12px;
              padding-top: 10px;
            }

            &.ant-menu-submenu-selected {
              .ant-menu-submenu-title {
                background-color: $primary-color;

                .ant-menu-title-content {
                  color: #fff;
                }

                .ant-menu-item-icon,
                .ant-menu-submenu-arrow {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .checkboxWrapper {
      margin-bottom: 1.5rem;

      :global {
        .ant-checkbox-group-item {
          width: 100%;
        }

        .ant-checkbox-wrapper {
          margin-left: 0;
          width: 100%;
          margin-bottom: 0.5rem;
          font-size: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}


.mediaSiderContainer {
  .groupTitle {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1rem;
    font-size: 14px;
    color: #687074;
  }
}
