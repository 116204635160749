@import 'App.scss';

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  color: #687074;
  margin-top: 0px !important;

  &_2 {
    @extend .title;
    font-size: 16px;
    line-height: 19px;
    margin-top: unset;
  }

  &_create {
    font-style: normal;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 26px !important;
  }
}

.subtitle {
  @extend .title;
  font-weight: normal;
  margin: 0 0 8px 0;
  font-size: 18px;
  line-height: 21px;
}

.functionTabs {
  height: auto;
  overflow: auto;
  .tabs {
    :global {
      .ant-tabs-tab-active {
        background-color: $primary-color !important;
        span {
          color: #fff;
        }
      }
    }
    .tab {
      overflow: auto;
    }
  }
}
