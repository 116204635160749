@import 'App.scss';

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #ffffff !important;
  display: flex;
  align-items: center;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));

  .headerContainer {
    width: 100%;
    display: flex;

    .leftContent {
      display: flex;
      align-items: stretch;
      // flex-grow: 1;
      width: 100%;

      .logo {
        height: 100%;
        display: flex;
        width: 205px;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          padding-right: 1rem;
        }

        .layoutTitle {
          padding: 0.125rem 0 0.125rem 1rem;
          border-left: 1px solid #e7ecf3;

          h6 {
            font-size: 14px;
            line-height: 1;
            font-weight: 700;
            margin: 0;
          }

          p {
            font-size: 10px;
            line-height: 1;
            margin: 0;
            margin-top: 0.5rem;
          }
        }
      }

      .menu {
        width: 100%;
        align-items: center;
        padding-left: 1rem;

        .menuItem {
          line-height: 1;
          height: 30px;
          display: inline-flex;
          align-items: center;
          color: #000;
          padding: 0 1rem;

          &::after {
            display: none;
          }

          a {
            text-decoration: none !important;
            font-size: 12px;
          }

          .menuIcon {
            font-size: 18px !important;
          }
        }

        :global .ant-menu-item-selected {
          background-color: $primary-color;
          border-radius: 5px;
          color: #fff;

          .ant-menu-title-content {
            a {
              color: #fff;
            }
          }
        }
      }
    }

    .rightContent {
      display: flex;
      justify-content: flex-end;
      min-width: 600px;
      align-items: center;

      .menu {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        margin-right: 1rem;

        .menuItem {
          &::after {
            display: none;
          }

          a {
            text-decoration: none !important;
            font-size: 12px;
          }

          .menuIcon {
            margin-right: 5px;
            font-size: 13px !important;
          }
        }
      }

      .userInfo {
        display: -webkit-box;
        align-items: center;

        .userName {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.pageHeader {
  padding: 16px 20px !important;
  background-color: #fff !important;
  box-sizing: border-box;
  height: auto !important;

  .headerContent {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .titleWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .projectIcon {
        margin-right: 16px;

        .iconWrapper {
          background: $primary-color;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          width: 32px;
          height: 28px;
          align-items: center;

          .iconFont {
            font-family: 'Raleway' sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff !important;
            text-transform: uppercase;
          }
        }
      }

      .breadCrumb {
        .breadCrumbItem {
          font-size: 18px;
        }
      }
    }
  }
}

@import 'App.scss';

.demoProjectCard {
  width: 320px;
  margin: 10px;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 6%);
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
}

.projectHeader {
  width: 40px;
  height: 40px;
  background: $primary-color;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  .iconFont {
    font-family: 'Raleway' sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff !important;
  }
}
