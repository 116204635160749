.container {
  width: 100%;
}

.root {
  :global .slick-slider {
    margin-top: 50px;
  }
}

.card {
  width: 280px;
  margin: 15px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 0px 20px 0px rgba(207, 207, 207, 1);
    //border-color: 'red';
  }

  .cardBody {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  div:nth-child(2) {
    height: 100%;
    position: relative;
  }

  .cardAvatar {
    height: 72px;

    & > span > img {
      object-fit: contain !important;
    }
  }

  .cardMeta > .ant-card-meta-detail {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btnWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 32%;

    .cardBtn {
      margin-top: 15px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      font-weight: 600;
      width: 215px;
      height: 40px;
      text-transform: uppercase;
    }

    .btnDisable {
      @extend .cardBtn;
      background: #ffffff;
      border: 1px solid var(--themeColor);
      color: var(--themeColor);

      &:hover:focus:active {
        color: #ffffff !important;
      }
    }
  }
}

