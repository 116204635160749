@import 'App.scss';

.rightColContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    display: flex;
    margin-bottom: 1rem;
  }

  h2 {
    line-height: 1.2;
    margin-bottom: 2rem;

    span {
      color: $primary-color;
      font-weight: 700;
    }
  }

  form {
    max-width: 436px;

    .input {
      margin-bottom: 1rem;
      width: 100% !important;
    }

    .links {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
    }
  }
}
