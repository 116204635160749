.title {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.projectCreateOrUpdateForm {
  .input,
  .select {
    margin-bottom: 1rem;
    width: 100% !important;
  }

  .links {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  //   .buttons {
  //     display: flex;
  //     justify-content: space-between;
  //   }
}
