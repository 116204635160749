.title {
  font-style: normal;
  font-weight: bold !important;
  font-size: 22px !important;
  line-height: 26px !important;
}

.dropDownLabel {
  @extend .title;
  color: #687074;
}
