.apitoCreateFormHideValidation {
  .ant-form-item-has-error {
    background: red !important;

    .ant-form-item-explain-error {
      opacity: 0 !important;
    }
  }
}

.contentFormContainer::-webkit-scrollbar {
  width: 0px;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .metaContentWrapper {
    display: flex;
    flex-wrap: wrap;

    .singleMetaContentWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      margin: 0 25px 0 0;

      .metaIcon {
        margin-right: 8px;
        width: 10px;
        height: 10px;
      }

      .meta {
        display: flex;
        flex-direction: column;

        .metaTitle {
          font-size: 14px;
          line-height: 16px;
        }

        .metaSubtitle {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
