@import 'App.scss';

$btnColor: #04910a;

.priceCard {
  margin: 10px;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 6%);
  border-radius: 10px;

  .priceWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .prevPrice {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
    }

    .price {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
    }
  }

  .cardBtn {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    height: 40px;
    font-weight: bold;
  }

  .title {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #0f2137;
  }

  .subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #0f2137;
  }

  .cardBody {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .infoListWrapper {
      padding: 25px 0;
      margin-left: -40px;

      ul {
        list-style-type: none;

        li > span {
          line-height: 28px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 0px;
          text-align: left;
          color: #343d48;
        }

        .checkIcon {
          margin-right: 8px;
          color: $primary-color;
        }
      }
    }
  }
}

.addonCard {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-bottom: 0.5rem;

  .cardAvater {
    text-align: center;
    padding-bottom: 0.5rem;
    padding-top: 2rem;

    & > span {
      background: #fff;
      color: #000000;

      img {
        object-fit: contain !important;
      }
    }

    & img {
      height: 66px;
    }
  }

  .metaWrapper {
    display: flex;
    // change min-height to place button dynamicaly
    text-align: center;
    flex-wrap: wrap;
    min-height: 190px;
    flex-direction: column;
    justify-content: space-between;

    :global .ant-card-meta {
      margin-bottom: 2rem;
    }
  }

  .cardMeta > .ant-card-meta-detail {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 280px;
  }

  .cardBtn {
    margin-top: 15px;
    font-weight: 500;
    padding: 5px;
    width: 132px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    &:hover,
    &:active,
    &:focus {
      color: #fff;
    }
  }

  .primaryCardBtn {
    color: #fff;
    background: $btnColor;
    border: none;

    &:hover,
    &:active,
    &:focus {
      border-color: lighten($color: #fff, $amount: 10%);
      background: (lighten($color: $btnColor, $amount: 5%));
    }
  }
}

.card:hover {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(207, 207, 207, 1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(207, 207, 207, 1);
  box-shadow: 0px 0px 20px 0px rgba(207, 207, 207, 1);
}

.projectCard {
  :global {
    .cardExtra {
      display: flex;
      align-items: center;

      .loading {
        margin: 0 !important;
        margin-right: 0.5rem !important;
      }
    }
  }
}

.enginCard {
  .rightColContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto !important;
  }

  .cardWrapper {
    display: flex;
    flex-wrap: wrap;

    .card {
      width: 326px;
      height: 370px;
      margin: 15px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 10px;

      &:hover,
      &:active,
      &:focus {
        box-shadow: 0px 0px 20px 0px rgba(207, 207, 207, 1);
        // border-color: lighten($color: var(--themeColor), $amount: 10%);
      }

      & > div:nth-child(2) {
        height: 100%;
        position: relative;
      }

      .cardAvatar {
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        & > span > img {
          object-fit: contain !important;
        }
      }

      .btnWrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 27%;

        .cardBtn {
          margin-top: 15px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 5px;
          font-weight: 600;
          width: 215px;
          height: 40px;
          text-transform: uppercase;
        }

        .btnDisable {
          @extend .cardBtn;
          background: #ffffff;
          border: 1px solid var(--themeColor);
          color: var(--themeColor);

          &:hover:focus:active {
            color: #ffffff !important;
          }
        }
      }
    }
  }

  .title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    display: block;
  }

  .leftColumn {
    .description {
      font-family: 'Helvetica Neue';
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
}
